// node modules
import React, { lazy, Suspense } from 'react'
import { graphql, PageProps } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'
import LazyLoad from 'react-lazyload'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// types
import type { HeroSlide, SalesRep } from 'types/homepage'
import type { Point } from 'types/point'
import type { Product } from 'types/product'

// sections
import Hero from 'components/pages/homepage/hero'
import SideBySideWithContent from 'components/shared/side-by-side-with-content'

const Recommended = lazy(() => import('components/pages/homepage/recommended'))
const Products = lazy(() => import('components/pages/homepage/products'))
const ActionBoxWithImage = lazy(
  () => import('components/pages/homepage/action-box-with-image')
)
const FindDealer = lazy(() => import('components/shared/find-dealer'))
const About = lazy(() => import('components/pages/homepage/about'))

const Contact = lazy(() => import('components/pages/homepage/contact'))

const IndexPage: React.FC<PageProps<Queries.IndexPageQuery>> = ({ data }) => {
  const PAGE = data.wpPage?.onepage
  const PAGE_SEO = data.wpPage?.seo!
  const PRODUCTS = data.allWpVehiclesArticle.nodes

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const HERO_SLIDES: HeroSlide[] =
    PAGE?.hero
      ?.filter((slide) => slide?.heroImg)
      ?.map((slide) => ({
        image: {
          src: slide?.heroImg?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: slide?.heroImg?.altText!,
        },
        objPosX: slide?.heroImgObjectPositionX!,
        heading: slide?.heroTitle!,
        buttonText: slide?.heroButton!,
        buttonLink: slide?.heroUrl!,
      })) || []

  const TEAM: SalesRep[] =
    PAGE?.handlowiec
      ?.filter((handlowiec) => handlowiec?.salerName)
      ?.map((salesRep) => ({
        image: {
          src: salesRep!.salerImg!.localFile!.childImageSharp!.gatsbyImageData!,
          alt: salesRep!.salerImg!.altText!,
        },
        name: salesRep!.salerName!,
        position: salesRep!.salerPosition!,
        region: salesRep!.salerRegion!,
        phone: salesRep!.salerPhoneNumber!,
        email: salesRep!.salerEmail!,
      })) || []

  const ALL_VEHICLES: Product[] =
    PRODUCTS?.filter(
      (product) =>
        product?.produkt?.productOptionsProductName &&
        product?.produkt?.productOptionsProductImage
    )
      ?.map(({ date, slug, produkt }) => ({
        image: {
          src: produkt?.productOptionsProductImage?.localFile?.childImageSharp!
            ?.gatsbyImageData,
          alt: produkt?.productOptionsProductImage?.altText!,
        },
        name: produkt?.productOptionsProductName!,
        priceFrom: produkt?.productOptionsPriceFrom!,
        noPrice: Boolean(produkt?.productOptionsNoPrice),
        category: produkt?.productOptionsProductType!,
        isRecommended: Boolean(produkt?.productOptionsIsRecommended)!,
        recommendedHeading: produkt?.productOptionsRecommendedHeading,
        recommendedDescription: produkt?.productOptionsRecommendedDescription,
        isNew: Boolean(produkt?.productOptionsIsNew),
        isPromotion: Boolean(produkt?.productOptionsIsPromotion),
        dateAdded: date!,
        slug: slug!,
      }))
      .sort(
        (a, b) =>
          new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()
      ) || []

  const RECOMMENDED_MODELS =
    ALL_VEHICLES?.filter(({ isRecommended }) => isRecommended) || []

  const ATV_MODELS =
    ALL_VEHICLES?.filter(({ category }) => category === 'ATV') || []

  const MOTORBIKE_MODELS =
    ALL_VEHICLES?.filter(({ category }) => category === 'Motocykl') || []

  const SSV_MODELS =
    ALL_VEHICLES?.filter(({ category }) => category === 'SSV') || []

  const UTV_MODELS =
    ALL_VEHICLES?.filter(({ category }) => category === 'UTV') || []

  const SERVICE_POINTS: Point[] =
    PAGE?.dealer
      ?.filter((dealer) => dealer?.dealerName && dealer?.dealerContact)
      ?.map((dealer) => ({
        name: dealer?.dealerName!,
        address: dealer?.dealerContact!,
        openingHours: dealer?.dealerHours?.map((item) => ({
          days: item?.dealerHoursDay!,
          hours: item?.dealerHoursHours!,
        }))!,
        phoneNumber: dealer?.dealerPhoneNumber!,
        routeLink: dealer?.dealerUrl!,
        latitude: Number(dealer?.dealerLat)!,
        longitude: Number(dealer?.dealerLng)!,
        isShowroom: Boolean(dealer?.dealerSalesOffice),
        isServicePoint: Boolean(dealer?.dealerService),
        id: uuidv4(),
        postCode: dealer?.dealerPostCode!,
        city: dealer?.dealerCity!,
      })) || []

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <Hero slides={HERO_SLIDES} />

      <LazyLoad once offset={225}>
        <Suspense fallback={<></>}>
          <Recommended heading="Polecane modele" models={RECOMMENDED_MODELS} />
        </Suspense>
      </LazyLoad>

      <LazyLoad once offset={225}>
        <Suspense fallback={<></>}>
          <Products heading="ATV" id="atv" products={ATV_MODELS} />
        </Suspense>
      </LazyLoad>

      <LazyLoad once offset={225}>
        <Suspense fallback={<></>}>
          <ActionBoxWithImage
            image={{
              src: PAGE?.separatorImg?.localFile?.childImageSharp
                ?.gatsbyImageData!,
              alt: PAGE?.separatorImg?.altText!,
            }}
            heading={PAGE?.separatorTitle!}
            buttonText="poznaj szczegóły"
            buttonLink={PAGE?.separatorUrl}
            className="first"
          />
        </Suspense>
      </LazyLoad>

      {SSV_MODELS.length > 0 && (
        <LazyLoad once offset={225}>
          <Suspense fallback={<></>}>
            <Products heading="SSV" id="ssv" products={SSV_MODELS} />
          </Suspense>
        </LazyLoad>
      )}

      {UTV_MODELS.length > 0 && (
        <LazyLoad once offset={225}>
          <Suspense fallback={<></>}>
            <Products heading="UTV" id="utv" products={UTV_MODELS} />
          </Suspense>
        </LazyLoad>
      )}

      {MOTORBIKE_MODELS.length > 0 && (
        <LazyLoad once offset={225}>
          <Suspense fallback={<></>}>
            <Products
              heading="Motocykle"
              id="motocykle"
              products={MOTORBIKE_MODELS}
            />
          </Suspense>
        </LazyLoad>
      )}

      <LazyLoad once offset={225}>
        <Suspense fallback={<></>}>
          <ActionBoxWithImage
            image={{
              src: PAGE?.separator2Img?.localFile?.childImageSharp
                ?.gatsbyImageData!,
              alt: PAGE?.separator2Img?.altText!,
            }}
            heading={PAGE?.separator2Title!}
            buttonText="poznaj szczegóły"
            buttonLink={PAGE?.separator2Url}
            className="second"
          />
        </Suspense>
      </LazyLoad>

      <LazyLoad once offset={225}>
        <Suspense fallback={<></>}>
          <FindDealer points={SERVICE_POINTS} page="homepage" />
        </Suspense>
      </LazyLoad>

      <LazyLoad once offset={225}>
        <Suspense fallback={<></>}>
          <About
            heading={PAGE?.aboutUsTitle!}
            firstImage={{
              src: PAGE?.aboutUsImg1?.localFile?.childImageSharp
                ?.gatsbyImageData!,
              alt: PAGE?.aboutUsImg1?.altText!,
            }}
            firstTitle={PAGE?.aboutUsHeader1!}
            firstDescription={PAGE?.aboutUsDescription1!}
            secondImage={{
              src: PAGE?.aboutUsImg2?.localFile?.childImageSharp
                ?.gatsbyImageData!,
              alt: PAGE?.aboutUsImg2?.altText!,
            }}
            secondTitle={PAGE?.aboutUsHeader2!}
            secondDescription={PAGE?.aboutUsDescription2!}
            thirdImage={{
              src: PAGE?.aboutUsImg3?.localFile?.childImageSharp
                ?.gatsbyImageData!,
              alt: PAGE?.aboutUsImg3?.altText!,
            }}
            thirdTitle={PAGE?.aboutUsHeader3!}
            thirdDescription={PAGE?.aboutUsDescription3!}
            buttonText="chcesz wiedzieć więcej<br/> o naszych modelach?"
            buttonLink="/o-nas"
          />
        </Suspense>
      </LazyLoad>

      <SideBySideWithContent
        heading={PAGE?.readyToDriveTitle!}
        firstImage={{
          src: PAGE?.readyToDriveImgLeft?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.readyToDriveImgLeft?.altText!,
        }}
        secondImage={{
          src: PAGE?.readyToDriveTitleRight?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.readyToDriveTitleRight?.altText!,
        }}
        subheading={PAGE?.readyToDriveHeader!}
        paragraph={PAGE?.readyToDriveDescription!}
        buttonText="Lista autoryzowanych serwisów"
        buttonLink="/znajdz-dealera"
        contentId="obsluga-serwisowa"
      />

      <LazyLoad once offset={225}>
        <Suspense fallback={<></>}>
          <Contact
            firstHeading="Kontakt i informacje"
            secondHeading="Masz pytania? Chętnie pomożemy"
            companyInfo={{
              address: PAGE?.contactCompanyName!,
              data: PAGE?.contactData!,
              phone: PAGE?.contactPhoneNumber!,
              email: PAGE?.contactEmail!,
            }}
            team={TEAM}
          />
        </Suspense>
      </LazyLoad>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage {
    wpPage(slug: { regex: "/onepage/" }) {
      onepage {
        hero {
          heroTitle
          heroButton
          heroUrl
          heroImgObjectPositionX
          heroImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: DOMINANT_COLOR
                  breakpoints: [1280, 1920]
                )
              }
            }
          }
        }
        priceListLink
        separatorImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        separatorTitle
        separatorUrl
        separator2Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        separator2Title
        separator2Url
        dealer {
          dealerName
          dealerContact
          dealerCity
          dealerPostCode
          dealerHours {
            dealerHoursDay
            dealerHoursHours
          }
          dealerPhoneNumber
          dealerLat
          dealerLng
          dealerUrl
          dealerService
          dealerSalesOffice
          dealerMobileService
        }
        aboutUsTitle
        aboutUsImg1 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
        aboutUsHeader1
        aboutUsDescription1
        aboutUsImg2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
        aboutUsHeader2
        aboutUsDescription2
        aboutUsImg3 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
        aboutUsHeader3
        aboutUsDescription3
        readyToDriveTitle
        readyToDriveImgLeft {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 650)
            }
          }
        }
        readyToDriveTitleRight {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
        readyToDriveHeader
        readyToDriveDescription
        readyToDriveUrl
        contactCompanyName
        contactData
        contactPhoneNumber
        contactEmail
        handlowiec {
          salerImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 250, height: 250)
              }
            }
          }
          salerName
          salerPosition
          salerRegion
          salerPhoneNumber
          salerEmail
        }
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
      seo {
        ...WpSEO
      }
    }
    allWpVehiclesArticle {
      nodes {
        date
        slug
        produkt {
          productOptionsIsNew
          productOptionsIsPromotion
          productOptionsPriceFrom
          productOptionsNoPrice
          productOptionsProductName
          productOptionsIsRecommended
          productOptionsRecommendedHeading
          productOptionsRecommendedDescription
          productOptionsProductType
          productOptionsProductImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 700)
              }
            }
          }
        }
      }
    }
  }
`
